import _ from "lodash";
import Axios from "axios";
import SHA256 from "crypto-js/sha256";

import { SEKOLAH_KODE, BASE_API_DEV, BASE_API_PROD } from "./main.config";

const { NODE_ENV } = process.env;

Axios.defaults.withCredentials = true;

let URL = null;
if (NODE_ENV === "production") {
  URL = BASE_API_PROD;
} else {
  URL = BASE_API_DEV;
}

/**
 * General
 */
export const getCancellation = () => {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();
  return source;
};

/**
 * Login, Logout and validate
 */
export const check = () =>
  Axios.get([URL, "check"].join("/"))
    .then((resp) => resp.data)
    .catch(({ response }) => _.get(response, "data", {}));
export const login = (data) => {
  const body = _.assign({}, data);
  if (!_.isEmpty(body.password)) {
    body.password = SHA256(body.password).toString();
  }
  return Axios.post([URL, "login"].join("/"), body)
    .then((resp) => resp.data)
    .catch(({ response }) => _.get(response, "data", {}));
};
export const logout = () =>
  Axios.post([URL, "logout"].join("/"))
    .then((resp) => resp.data)
    .catch(({ response }) => _.get(response, "data", {}));
export const forget = (body) =>
  Axios.post(
    [URL, "forget"].join("/"),
    _.assign({}, body, {
      sekolah_kode: SEKOLAH_KODE,
    }),
  )
    .then((resp) => resp.data)
    .catch(({ response }) => _.get(response, "data", {}));

/**
 * Base API
 */
const generateBase = (base) => ({
  index: (query) =>
    Axios.get(base, { params: query })
      .then((resp) => resp.data)
      .catch(({ response }) => _.get(response, "data", {})),
  show: (id, query) =>
    Axios.get([base, id].join("/"), { params: query })
      .then((resp) => resp.data)
      .catch(({ response }) => _.get(response, "data", {})),
  store: (data) =>
    Axios.post(base, data)
      .then((resp) => resp.data)
      .catch(({ response }) => _.get(response, "data", {})),
  update: (id, data) =>
    Axios.put([base, id].join("/"), data)
      .then((resp) => resp.data)
      .catch(({ response }) => _.get(response, "data", {})),
  updateMany: (params, data) =>
    Axios.put(base, data, { params })
      .then((resp) => resp.data)
      .catch(({ response }) => _.get(response, "data", {})),
  remove: (id) =>
    Axios.delete([base, id].join("/"))
      .then((resp) => resp.data)
      .catch(({ response }) => _.get(response, "data", {})),
  removeMany: (params) =>
    Axios.delete([base].join("/"), { params })
      .then((resp) => resp.data)
      .catch(({ response }) => _.get(response, "data", {})),

  getImageURI: (fname) => ["" + URL, "public/image", fname].join("/"),
});

/**
 * Asset Resource
 */
export const asset = (() => {
  const base = [URL, "asset"].join("/");
  return _.assign({}, generateBase("" + base), {
    download: (id) =>
      Axios.get([base, id].join("/"), { responseType: "arraybuffer" }).catch(
        (error) => error.response,
      ),
    getURL: (id) => [base, id].join("/"),
  });
})();

/**
 * Wilayah Resource
 */
export const wilayah = (() => {
  const base = [URL, "wilayah"].join("/");
  return _.assign({}, generateBase("" + base), {
    query: (params) =>
      Axios.get([base, "query"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * Catatan Resource
 */
export const catatan = (() => {
  const base = [URL, "catatan"].join("/");
  return _.assign({}, generateBase("" + base), {});
})();

/**
 * Ekstrakurikuler Resource
 */
export const ekstrakurikuler = (() => {
  const base = [URL, "ekstrakurikuler"].join("/");
  return _.assign({}, generateBase("" + base), {
    download: (id) =>
      Axios.get([base, id, "unduh"].join("/"), {
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    print: (id) =>
      Axios.get([base, id, "cetak"].join("/"), {
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    showNilai: (id, params) =>
      Axios.get([base, id, "nilai"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveNilai: (ekskul_id, data) =>
      Axios.post([base, ekskul_id, "nilai"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * Guru Resource
 */
export const guru = (() => {
  const base = [URL, "guru"].join("/");
  return _.assign({}, generateBase("" + base), {});
})();

/**
 * hafalan Resource
 */
export const hafalan = (() => {
  const base = [URL, "hafalan"].join("/");
  return _.assign({}, generateBase("" + base), {
    pushMuridById: (id, array) =>
      Axios.post([base, id, "tambah-murid"].join("/"), array)
        .then((resp) => resp.data)
        .catch((error) => error.response),
    showNilaiHafalan: (id, params) =>
      Axios.get([base, id, "nilai"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveNilaiHafalan: (id, list_murid) =>
      Axios.post([base, id, "nilai"].join("/"), list_murid)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    downloadNilaiHafalan: (params) =>
      Axios.get([base, "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
  });
})();

/**
 * jenisPelanggaran Resource
 */
export const jenisPelanggaran = (() => {
  const base = [URL, "jenis-pelanggaran"].join("/");
  return _.assign({}, generateBase("" + base));
})();

/**
 * Proyek P5 Resource
 */
export const proyekP5 = (() => {
  const base = [URL, "proyek-p5"].join("/");
  return _.assign({}, generateBase("" + base));
})();

/**
 * Kelompok P5 Resource
 */
export const kelompokP5 = (() => {
  const base = [URL, "kelompok-p5"].join("/");
  return _.assign({}, generateBase("" + base), {
    showNilai: (id, params) =>
      Axios.get([base, id, "nilai-p5"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showProgresMasukan: (id, params) =>
      Axios.get([base, id, "progres-masukan"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveNilai: (kelompok_id, list_nilai) =>
      Axios.put([base, kelompok_id, "nilai-p5"].join("/"), list_nilai)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * karyawan Resource
 */
export const karyawan = (() => {
  const base = [URL, "karyawan"].join("/");
  return _.assign({}, generateBase("" + base), {
    changePassword: (id, data) =>
      Axios.put([base, id, "password"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    resetPassword: (id, data) =>
      Axios.put([base, id, "reset-password"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * jurusan Resource
 */
export const jurusan = (() => {
  const base = [URL, "jurusan"].join("/");
  return _.assign({}, generateBase("" + base), {
    query: (params) =>
      Axios.get([base, "query"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    summary: (params) =>
      Axios.get([base, "rangkuman"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * kbm Resource
 */
export const kbm = (() => {
  const base = [URL, "kbm"].join("/");
  return _.assign({}, generateBase("" + base), {
    showNilai: (id, params) =>
      Axios.get([base, id, "nilai"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showKelas: (id, params) =>
      Axios.get([base, id, "kelas"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    printNilai: (id, params) =>
      Axios.get([base, id, "cetak-nilai"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    saveNilai: (kbm_id, list_nilai) =>
      Axios.post([base, kbm_id, "nilai"].join("/"), list_nilai)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * kelas Resource
 */
export const kelas = (() => {
  const base = [URL, "kelas"].join("/");
  return _.assign({}, generateBase("" + base), {
    indexKelompokP5: (params) =>
      Axios.get([base, "kelompok-p5"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showKelompokP5: (id, params) =>
      Axios.get([base, id, "kelompok-p5"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    ikhtisarAkademik: (params) =>
      Axios.get([base, "ikhtisar-akademik"].join("/"), { params })
        .then((resp) => resp.data)
        .catch((error) => error.response),
    ikhtisarUmum: (params) =>
      Axios.get([base, "ikhtisar-umum"].join("/"), { params })
        .then((resp) => resp.data)
        .catch((error) => error.response),
    ikhtisarKesiswaan: (params) =>
      Axios.get([base, "ikhtisar-kesiswaan"].join("/"), { params })
        .then((resp) => resp.data)
        .catch((error) => error.response),
    ikhtisarTataTertib: (params) =>
      Axios.get([base, "ikhtisar-tata-tertib"].join("/"), { params })
        .then((resp) => resp.data)
        .catch((error) => error.response),
    downloadPresensi: (params) =>
      Axios.get([base, "presensi", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printPresensi: (params) =>
      Axios.get([base, "presensi", "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    showKBM: (id) =>
      Axios.get([base, id, "kbm"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showCatatanKonseling: (id) =>
      Axios.get([base, id, "catatan-konseling"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showPeminatan: (id) =>
      Axios.get([base, id, "peminatan"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showWaliMurid: (id) =>
      Axios.get([base, id, "wali-murid"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showProgresMasukan: (id, opts) =>
      Axios.get([base, id, "progres-masukan"].join("/"), opts)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showProgresPengisianNS: (id, opts) =>
      Axios.get([base, id, "progres-masukan-nilai-sekolah"].join("/"), opts)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showDetilRapor: (id) =>
      Axios.get([base, id, "detil-rapor"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showMurid: (id) =>
      Axios.get([base, id, "murid"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveDetilRapor: (id, data) =>
      Axios.post([base, id, "detil-rapor"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    pushMurid: (data) =>
      Axios.post([base, "push-murid"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    printKartuPelajar: (id, params) =>
      Axios.get([base, id, "kartu-pelajar", "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
  });
})();

/**
 * kelompok Resource
 */
export const kelompok = (() => {
  const base = [URL, "kelompok"].join("/");
  return _.assign({}, generateBase("" + base), {
    updateBulk: (data) =>
      Axios.put([base].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * Kuesioner Resource
 */
export const kuesioner = (() => {
  const base = [URL, "kuesioner"].join("/");
  return _.assign({}, generateBase("" + base), {
    download: (id) =>
      Axios.get([base, id, "unduh"].join("/"), {
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    query: (query) =>
      Axios.get([base, "query"].join("/"), { params: query })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showHasil: (id) =>
      Axios.get([base, id, "hasil"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showHasilPerUser: (id, user_id) =>
      Axios.get([base, id, "hasil", user_id].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * mataPelajaran Resource
 */
export const mataPelajaran = (() => {
  const base = [URL, "mata-pelajaran"].join("/");
  return _.assign({}, generateBase("" + base), {
    index: (kurikulum, query) =>
      Axios.get(base, { params: { ...query, kurikulum } })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showKompetensi: (id, params) =>
      Axios.get([base, id, "kompetensi"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showPembelajaran: (id, params) =>
      Axios.get([base, id, "pembelajaran"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showTujuanPembelajaran: (id, params) =>
      Axios.get([base, id, "tujuan-pembelajaran"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indukIndex: (params) =>
      Axios.get([base, "induk"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indukUpdate: (data) =>
      Axios.post([base, "induk"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    updateBulk: (data) =>
      Axios.put([base, "bulk"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    updateBebanBulk: (data) =>
      Axios.put([base, "beban"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * Murid Resource
 */
export const murid = (() => {
  const base = [URL, "murid"].join("/");
  return _.assign({}, generateBase("" + base), {
    downloadKelengkapan: () =>
      Axios.get([base, "kelengkapan", "unduh"].join("/"), {
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadBiodata: (params) =>
      Axios.get([base, "biodata", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    indexActive: (params) =>
      Axios.get([base, "aktif"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    withinKelas: (params) =>
      Axios.get([base, "dengan-kelas"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indexKelengkapan: (params) =>
      Axios.get([base, "kelengkapan"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    tanpaKelas: (params) =>
      Axios.get([base, "tanpa-kelas"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indexKunci: (params) =>
      Axios.get([base, "kunci"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indexNIS: (params) =>
      Axios.get([base, "nis"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    ikhtisarUmum: (id) =>
      Axios.get([base, id, "ikhtisar-umum"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    ikhtisarTataTertib: (id) =>
      Axios.get([base, id, "ikhtisar-tata-tertib"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    ikhtisarKesiswaan: (id) =>
      Axios.get([base, id, "ikhtisar-kesiswaan"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    ikhtisarAkademik: (id, params) =>
      Axios.get([base, id, "ikhtisar-akademik"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    listKelas: (id) =>
      Axios.get([base, id, "list-kelas"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showHakAkses: (id, query) =>
      Axios.get([base, id, "hak-akses"].join("/"), { params: query })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showNilaiRapor: (query) =>
      Axios.get([base, "nilai", "rapor"].join("/"), { params: query })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showPilihanJurusan: (id, query) =>
      Axios.get([base, id, "pilihan-jurusan"].join("/"), { params: query })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showFoto: (id, params) =>
      Axios.get([base, id, "foto"].join("/"), {
        responseType: "arraybuffer",
        params,
      }).catch((error) => error.response),
    showPrestasi: (id, params) =>
      Axios.get([base, id, "prestasi"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showPoin: (id, params) =>
      Axios.get([base, id, "poin"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showCatatanKonseling: (id, params) =>
      Axios.get([base, id, "catatan-konseling"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showTahunAjaran: (id, params) =>
      Axios.get([base, id, "tahun-ajaran"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showTataTertib: (id, params) =>
      Axios.get([base, id, "tata-tertib"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showUnregistered: (id, params) =>
      Axios.get([base, id, "tidak-terdaftar"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    downloadTataTertib: (id, params) =>
      Axios.get([base, id, "tata-tertib", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadPrestasi: (id, params) =>
      Axios.get([base, id, "prestasi", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadPrestasiAkademik: (id, params) =>
      Axios.get([base, id, "prestasi-akademik", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadPrestasiEkstrakurikuler: (id, params) =>
      Axios.get([base, id, "prestasi-ekstrakurikuler", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printSuratPernyataan: (id, params) =>
      Axios.get([base, id, "surat-pernyataan", "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printTataTertib: (id, params) =>
      Axios.get([base, id, "tata-tertib", "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printPrestasi: (id, params) =>
      Axios.get([base, id, "prestasi", "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printKartuPelajar: (id, params) =>
      Axios.get([base, id, "kartu-pelajar", "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printRapor: (id, params) =>
      Axios.get([base, id, "rapor"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    showKelas: (id, params) =>
      Axios.get([base, id, "kelas"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    changePassword: (id, data) =>
      Axios.put([base, id, "password"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    updateBaseData: (id, data) =>
      Axios.put([base, id, "base"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    mutasiKeluar: (id, data) =>
      Axios.put([base, id, "mutasi-keluar"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showKuesioner: (id, params) =>
      Axios.get([base, id, "kuesioner"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveHasilKuesioner: (id, data) =>
      Axios.post([base, id, "hasil-kuesioner"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    storeKunci: (data) =>
      Axios.post([base, "kunci"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * nilai Resource
 */
export const nilai = (() => {
  const base = [URL, "nilai"].join("/");
  return _.assign({}, generateBase("" + base), {
    showRank: (params) =>
      Axios.get([base, "peringkat"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showRekapitulasi: (params) =>
      Axios.get([base, "rekapitulasi"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    downloadRank: (params) =>
      Axios.get([base, "peringkat", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
  });
})();

/**
 * nilaiSekolah Resource
 */
export const nilaiSekolah = (() => {
  const base = [URL, "nilai-sekolah"].join("/");
  return _.assign({}, generateBase("" + base), {
    updateNilaiUjianSekolah: (id, data) =>
      Axios.put([base, "ujian-sekolah"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showByKbm: (id, params) =>
      Axios.get([base, "kbm", id].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    downloadUS: (id, params) =>
      Axios.get([base, "kbm", id, "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadUSPerKelas: (id, params) =>
      Axios.get([base, "kelas", id, "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
  });
})();

/**
 * ppdb Resource
 */
export const ppdb = (() => {
  const base = [URL, "ppdb"].join("/");
  return _.assign({}, generateBase("" + base), {
    indexNilai: (params) =>
      Axios.get([base, "nilai"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    status: (params) =>
      Axios.get([base, "status"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    kunciPPDB: (params) =>
      Axios.get([base, "kunci"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indexKuota: () =>
      Axios.get([base, "list-kuota"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indexSMP: () =>
      Axios.get([base, "list-smp"].join("/"))
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    indexTahunPenerimaan: (query) =>
      Axios.get([base, "tahun-penerimaan"].join("/"), { params: query })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    resetPasswordPeserta: (id, data) =>
      Axios.post([base, id, "reset-password"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveNilaiTes: (data) =>
      Axios.post([base, "nilai"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveNilaiWawancara: (list_peserta, params) =>
      Axios.post([base, "nilai-wawancara"].join("/"), list_peserta, { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveHasilSeleksi: (list_peserta) =>
      Axios.post([base, "hasil-seleksi"].join("/"), list_peserta)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    storeLite: (item) =>
      Axios.post([base, "lite"].join("/"), item)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    verify: (data) =>
      Axios.post([base, "verify"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    download: (params) =>
      Axios.get([base, "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadSummary: (params) =>
      Axios.get([base, "unduh-rekap"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printKartuPeserta: (id, params) =>
      Axios.get([base, id, "cetak-kartu"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printKesanggupan: (id, params) =>
      Axios.get([base, id, "cetak-kesanggupan"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
  });
})();

/**
 * prestasi Resource
 */
export const prestasi = (() => {
  const base = [URL, "prestasi"].join("/");
  return _.assign({}, generateBase("" + base), {
    query: (params) =>
      Axios.get([base, "q"].join("/"), { params })
        .then((resp) => resp.data)
        .catch((error) => error.response),
    print: (params) =>
      Axios.get([base, "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    storeLainnya: (data) =>
      Axios.post([base, "lainnya"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    updateLainnya: (id, data) =>
      Axios.put([base, id, "lainnya"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    download: (jenis, params) =>
      Axios.get([base, "unduh", jenis].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    generatePoinAkademik: (body) =>
      Axios.post([base, "muat-ulang", "akademik"].join("/"), body).catch(
        (error) => error.response,
      ),
    generatePoinEkstrakurikuler: (body) =>
      Axios.post([base, "muat-ulang", "ekstrakurikuler"].join("/"), body).catch(
        (error) => error.response,
      ),
  });
})();

/**
 * rapor Resource
 */
export const rapor = (() => {
  const base = [URL, "rapor"].join("/");
  return _.assign({}, generateBase("" + base), {
    printUTS: (params) =>
      Axios.get([base, "uts"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printUAS: (params) =>
      Axios.get([base, "uas"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printP5: (params) =>
      Axios.get([base, "p5"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printUTSHafalan: (params) =>
      Axios.get([base, "uts-hafalan"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printUASHafalan: (params) =>
      Axios.get([base, "uas-hafalan"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printKHS: (params) =>
      Axios.get([base, "khs"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printSKL: (params) =>
      Axios.get([base, "skl"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printKelulusan: (params) =>
      Axios.get([base, "kelulusan"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printIjazah: (params) =>
      Axios.get([base, "ijazah"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    printLembarMutasi: (params) =>
      Axios.get([base, "lembar-mutasi"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadDetilRapor: (params) =>
      Axios.get([base, "detil-rapor", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadCover: (params) =>
      Axios.get([base, "cover", "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
  });
})();

/**
 * role Resource
 */
export const role = (() => {
  const base = [URL, "role"].join("/");
  return _.assign({}, generateBase("" + base), {});
})();

/**
 * sekolah Resource
 */
export const sekolah = (() => {
  const base = [URL, "sekolah"].join("/");
  return _.assign({}, generateBase("" + base), {});
})();

/**
 * Pengumuman Resource
 */
export const pengumuman = (() => {
  const base = [URL, "pengumuman"].join("/");
  return _.assign({}, generateBase("" + base), {
    query: (params) =>
      Axios.get([base, "query"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * referensi Resource
 */
export const referensi = (() => {
  const base = [URL, "referensi"].join("/");
  return _.assign({}, generateBase("" + base), {});
})();

/**
 * tahunAjaran Resource
 */
export const tahunAjaran = (() => {
  const base = [URL, "tahun-ajaran"].join("/");
  return _.assign({}, generateBase("" + base), {
    downloadDKN: (id, params) =>
      Axios.get([base, id, "unduh-dkn"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadDKNAkhir: (params) =>
      Axios.get([base, "unduh-dkn-akhir"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    downloadBukuNilai: (params) =>
      Axios.get([base, "unduh-buku-nilai"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    showStatusKelulusan: (id, query) =>
      Axios.get([base, id, "status-kelulusan"].join("/"), { params: query })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    showPengaturanP5: (id, query) =>
      Axios.get([base, id, "pengaturan-p5"].join("/"), { params: query })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    updatePengaturanP5: (id, data) =>
      Axios.put([base, id, "pengaturan-p5"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

/**
 * Tata Tertib Resource
 */
export const tataTertib = (() => {
  const base = [URL, "tata-tertib"].join("/");
  return _.assign({}, generateBase("" + base), {
    query: (params) =>
      Axios.get([base, "q"].join("/"), { params })
        .then((resp) => resp.data)
        .catch((error) => error.response),
    print: (params) =>
      Axios.get([base, "cetak"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
    download: (params) =>
      Axios.get([base, "unduh"].join("/"), {
        params,
        responseType: "arraybuffer",
      }).catch((error) => error.response),
  });
})();

/**
 * Wali Murid Resource
 */
export const waliMurid = (() => {
  const base = [URL, "wali-murid"].join("/");
  return _.assign({}, generateBase("" + base), {
    showKuesioner: (id, params) =>
      Axios.get([base, id, "kuesioner"].join("/"), { params })
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    saveHasilKuesioner: (id, data) =>
      Axios.post([base, id, "hasil-kuesioner"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    changePassword: (id, data) =>
      Axios.put([base, id, "password"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
    resetPassword: (id, data) =>
      Axios.put([base, id, "reset-password"].join("/"), data)
        .then((resp) => resp.data)
        .catch(({ response }) => _.get(response, "data", {})),
  });
})();

export default {
  getCancellation,

  check,
  login,
  logout,

  catatan,
  referensi,
  ekstrakurikuler,
  guru,
  hafalan,
  jenisPelanggaran,
  karyawan,
  kbm,
  kelas,
  kelompok,
  kuesioner,
  mataPelajaran,
  murid,
  nilai,
  nilaiSekolah,
  ppdb,
  prestasi,
  jurusan,
  rapor,
  role,
  sekolah,
  pengumuman,
  tahunAjaran,
  tataTertib,
  waliMurid,
};
